<template>
  <el-container>
    <el-main>
      <el-form ref="form" :model="form" label-width="160px" :rules="rules">
        <el-form-item label="排名：">
          <el-input type="number" :min="0" v-model="form.rank"></el-input>
        </el-form-item>
        <el-form-item label="文章主图：" prop="picture">
          <ReadyUploadSource
            @getSource="getImg"
            @removeThis="removeImg"
            :path="form.picture"
            :showStyle="{
              width: '200px',
              height: '100px',
            }"
          ></ReadyUploadSource>
        </el-form-item>
        <el-form-item label="文章标题：" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="文章副标题：">
          <el-input v-model="form.subtitle"></el-input>
        </el-form-item>
        <el-form-item label="文章分类：" prop="article_classify_id">
          <el-select v-model="form.article_classify_id" clearable style="width: 100%" placeholder="请选择">
            <el-option v-for="item in typeList" :key="item.id" :label="item.classify_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新闻时间：">
          <el-date-picker v-model="time" type="datetime" placeholder="默认为当前时间，可以选择时间" @change="getTime"></el-date-picker>
          <span style="color: #999; margin-left: 10px">不填则默认为当前时间</span>
        </el-form-item>
        <el-form-item label="是否显示：">
          <el-switch v-model="form.is_show" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item v-show="!form.id" label="是否推送：">
          <el-switch v-model="form.is_push" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="文章详情：" prop="details">
          <div>
            <RichText :height="300" :width="700" :richTxt="form.details" @soninfo="getRichTxt"></RichText>
          </div>
        </el-form-item>
        <Preservation @preservation="submit('form')">
          <router-link to="/article/articleList" slot="return" v-if="form.id" style="margin-right: 10px">
            <el-button>返回</el-button>
          </router-link>
        </Preservation>
      </el-form>
    </el-main>
  </el-container>
</template>

<script>
import RichText from '@/components/richText';
import ReadyUploadSource from '@/components/readyUploadSource';
import Preservation from '@/components/preservation';

export default {
  components: {
    RichText,
    ReadyUploadSource,
    Preservation,
  },
  data() {
    return {
      time: '',
      typeList: [],
      classify_name: '',
      rules: {
        picture: [{ required: true, message: '请添加文章主图', trigger: 'blur' }],
        title: [{ required: true, message: '请填写文章标题', trigger: 'blur' }],
        // subtitle: [{ required: true, message: '请填写文章副标题', trigger: 'blur' }],
        article_classify_id: [{ required: true, message: '请选择文章分类', trigger: 'blur' }],
        details: [{ required: true, message: '请填写文章详情', trigger: 'blur' }],
      },
      form: {
        article_classify_id: '',
        rank: '',
        picture: '',
        subtitle: '',
        title: '',
        pubdate: '',
        details: '',
        is_show: 1,
        is_push: 1,
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        this.$store.state.utils.is_update = 1;
      },
      deep: true,
    },
  },
  created() {
    this.getType();
    let info = this.$route.query.info;
    if (info) {
      let utils = this.$store.state.utils;
      utils.page = Number(this.$route.query.page);
      utils.rows = Number(this.$route.query.rows);
      utils.is_record = !0;
      info = JSON.parse(info);
      delete info.create_time;
      delete info.update_time;
      this.time = new Date(info.pubdate * 1000);
      this.form = info;
    }
  },
  methods: {
    submit(val) {
      this.$refs[val].validate(valid => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          let url = form.id ? this.$api.set.editArticle : this.$api.set.addArticle;
          if (!this.time) {
            this.time = new Date();
            form.pubdate = Date.parse(new Date()) / 1000;
          }
          form.rank = form.rank == '' ? 0 : form.rank;
          this.$axios.post(url, form).then(res => {
            if (res.code == 0) {
              this.$message({
                message: form.id ? '编辑成功' : '添加成功',
                type: 'success',
              });
              this.$router.push('/article/articleList');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getType() {
      this.$axios
        .post(this.$api.set.listArticleClass, {
          classify_name: this.classify_name,
        })
        .then(res => {
          if (res.code == 0) {
            this.typeList = res.result.list;
          }
        });
    },
    getImg(val) {
      this.form.picture = val.path;
    },
    removeImg() {
      this.form.picture = '';
    },
    getTime(val) {
      if (val) this.form.pubdate = val.getTime() / 1000;
    },
    getRichTxt(val) {
      this.form.details = val;
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: #fff;

  .el-main {
    .el-form {
      padding: 0px 0 60px 0;
      .el-form-item {
        width: 800px;
        /deep/ .el-date-editor.el-input {
          width: 300px;
        }
      }
    }
  }
}
</style>
